import classNames from 'classnames';
import { Loading } from 'components';
import { useGetOptionLibraries, useGetOptionsConnections } from 'hooks';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { ArrowLeft, ChevronDown, ChevronUp, Minus } from 'react-feather';
import Skeleton from 'react-loading-skeleton';
import { Collapse } from 'reactstrap';
import './ContentAttemptLayout.scss';
import { ContentUserDataAttempt } from 'types/student-report';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppBreadCrumb, CrumbItem } from 'components/AppBreadCrumb';

import { observer } from 'mobx-react-lite';
import { useAppStore } from 'hooks/useAppStore';
import { ContentResultContainer } from '../ContentResults';
import { MachineName } from '@spiderbox/common';

type ContentAttemptLayoutProps = { isLoading: boolean; attempt: ContentUserDataAttempt; crumbs?: CrumbItem[] };

export const ContentAttemptLayout = observer(({ isLoading, attempt, crumbs = [] }: ContentAttemptLayoutProps) => {
  const [isGeneralInfoOpen, setIsGeneralInfoOpen] = useState(true);
  const [isDetailInfoOpen, setIsDetailInfoOpen] = useState(true);
  const [isLearnerAnswerOpen, setIsLearnerAnswerOpen] = useState(true);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { ltiMode } = useAppStore();

  const { data: libraries, isLoading: isLibrariesLoading } = useGetOptionLibraries();
  const { data: connections = [], isLoading: isConnectionsLoading } = useGetOptionsConnections({ cacheTime: 300000 });

  const mappingTitle = (libraryName: string) => {
    const library = libraries?.find(lib => lib.metadata.machineName === libraryName);
    return library?.title || libraryName;
  };

  const mobileCrumbs = useMemo(() => {
    if (!attempt) return [];

    return [
      {
        path: `/learner-reports/${attempt.user.id}?${searchParams}`,
        title: attempt.user.fullName || attempt.user.email,
      },
      { path: '', title: `Attempt ${attempt.order + 1}`, unclickable: true },
    ];
  }, [attempt]);

  const ltiModeCrumbs = crumbs.map((crumb, index) => {
    if (index === 0) {
      return {
        ...crumb,
        title: 'Back',
      };
    }

    return crumb;
  });

  return (
    <div className="flex-fill d-flex flex-column">
      {isLoading ? (
        <Loading />
      ) : (
        <div className={classNames('content-attempt-layout flex-fill', { 'rounded-4': !ltiMode })}>
          <div className="d-none py-3 px-4 border-bottom border-gray-100 d-md-flex justify-content-between">
            <div className="d-flex flex-column gap-1">
              <AppBreadCrumb
                crumbs={ltiMode ? ltiModeCrumbs : crumbs}
                displayFull
              />
              {!ltiMode && (
                <div className="d-flex align-items-center gap-2">
                  {searchParams.get('user') && (
                    <div
                      className="gray-900 font-size-16 fw-semibold text-truncate"
                      style={{ maxWidth: 300 }}
                    >
                      {searchParams.get('user')}
                    </div>
                  )}

                  {searchParams.get('platformName') && (
                    <>
                      <div
                        className="bg-neutral-200 rounded-circle"
                        style={{ width: 6, height: 6 }}
                      />

                      <div className="text-neutral-500">{searchParams.get('platformName')}</div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="d-flex d-md-none align-items-center gap-2 pb-3">
            <ArrowLeft
              size={24}
              className="text-neutral-600 cursor-pointer me-2"
              onClick={() => navigate(mobileCrumbs[0].path)}
            />

            <AppBreadCrumb crumbs={mobileCrumbs} />
          </div>

          <div
            className="d-flex flex-column py-0 px-0 py-md-3 px-md-4"
            style={{ gap: 20 }}
          >
            <div className={classNames({ 'border border-neutral-200 rounded-3 bg-white': isGeneralInfoOpen })}>
              <div
                onClick={() => setIsGeneralInfoOpen(!isGeneralInfoOpen)}
                className={classNames(
                  'p-3 rounded-3 border-neutral-200 d-flex align-items-center cursor-pointer bg-white',
                  isGeneralInfoOpen ? 'border-bottom rounded-bottom-0' : 'border',
                )}
              >
                {isGeneralInfoOpen ? (
                  <ChevronUp
                    strokeWidth={2}
                    size={20}
                    className="gray-900"
                  />
                ) : (
                  <ChevronDown
                    strokeWidth={2}
                    size={20}
                    className="gray-900"
                  />
                )}

                <div className="ms-3 gray-900 font-size-16 fw-semibold">General Information</div>
              </div>

              <Collapse isOpen={isGeneralInfoOpen}>
                <div className="px-3 px-md-4 py-3">
                  <div className="rounded-3">
                    <div className="content-info-item row mx-0">
                      <div className="gray-900 fw-medium col-6 col-md-3 px-0">User</div>
                      <div className="gray-900 col-6 col-md-9">{attempt.user.fullName}</div>
                    </div>

                    <div className="content-info-item row mx-0">
                      <div className="gray-900 fw-medium col-6 col-md-3 px-0">Content Name</div>
                      <div
                        className="gray-900 col-6 col-md-9"
                        data-cy="content-name"
                      >
                        {attempt.content.title}
                      </div>
                    </div>

                    <div className="content-info-item row mx-0">
                      <div className="gray-900 fw-medium col-6 col-md-3 px-0">Content Type</div>
                      <div
                        className="gray-900 col-6 col-md-9"
                        data-cy="library-name"
                      >
                        {isLibrariesLoading ? <Skeleton /> : mappingTitle(attempt.content.library)}
                      </div>
                    </div>

                    <div className="content-info-item row mx-0">
                      <div className="gray-900 fw-medium col-6 col-md-3 px-0">LMS Connection</div>
                      <div
                        className="gray-900 col-6 col-md-9"
                        data-cy="lms-connection-name"
                      >
                        {isConnectionsLoading ? (
                          <Skeleton />
                        ) : (
                          connections.find(connection => connection.kid === attempt.platformId)?.platformName
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Collapse>
            </div>

            <div className={classNames({ 'border border-neutral-200 rounded-3 bg-white': isDetailInfoOpen })}>
              <div
                onClick={() => setIsDetailInfoOpen(!isDetailInfoOpen)}
                className={classNames(
                  'p-3 rounded-3 border-neutral-200 d-flex align-items-center cursor-pointer bg-white',
                  isDetailInfoOpen ? 'border-bottom rounded-bottom-0' : 'border',
                )}
              >
                {isDetailInfoOpen ? (
                  <ChevronUp
                    strokeWidth={2}
                    size={20}
                    className="gray-900"
                  />
                ) : (
                  <ChevronDown
                    strokeWidth={2}
                    size={20}
                    className="gray-900"
                  />
                )}

                <div className="ms-3 gray-900 font-size-16 fw-semibold">Content Details</div>
              </div>

              <Collapse isOpen={isDetailInfoOpen}>
                <div className="px-3 px-md-4 py-3">
                  <div className="rounded-3">
                    <div className="content-info-item row mx-0">
                      <div className="gray-900 fw-medium col-6 col-md-3 px-0">Learner&apos;s Attempt</div>
                      <div
                        className="gray-900 col-6 col-md-9"
                        data-cy="attempt-order"
                      >
                        Attempt {attempt.order + 1}
                      </div>
                    </div>

                    <div className="content-info-item row mx-0">
                      <div className="gray-900 fw-medium col-6 col-md-3 px-0">Start Time</div>
                      <div
                        className="gray-900 col-6 col-md-9"
                        data-cy="attempt-start-time"
                      >
                        {moment(attempt.openedAt).format('DD MMM YYYY, HH:mm:ss')}
                      </div>
                    </div>

                    <div className="content-info-item row mx-0">
                      <div className="gray-900 fw-medium col-6 col-md-3 px-0">End Time</div>
                      <div
                        className="gray-900 col-6 col-md-9"
                        data-cy="attempt-end-time"
                      >
                        {moment(attempt.finishedAt).format('DD MMM YYYY, HH:mm:ss')}
                      </div>
                    </div>

                    <div className="content-info-item row mx-0">
                      <div className="gray-900 fw-medium col-6 col-md-3 px-0">Spent</div>
                      <div
                        className="gray-900 col-6 col-md-9"
                        data-cy="attempt-duration"
                      >
                        {attempt.duration}
                      </div>
                    </div>

                    <div className="content-info-item row mx-0">
                      <div className="gray-900 fw-medium col-6 col-md-3 px-0">Total Score</div>
                      <div
                        className="gray-900 col-6 col-md-9"
                        data-cy="attempt-score"
                      >
                        {attempt.totalScore !== null ? (
                          `${attempt.score}/${attempt.maxScore} (${attempt.totalScore} %)`
                        ) : (
                          <Minus size={16} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Collapse>
            </div>

            <div className={classNames({ 'border border-neutral-200 rounded-3 bg-white': isLearnerAnswerOpen })}>
              <div
                onClick={() => setIsLearnerAnswerOpen(!isLearnerAnswerOpen)}
                className={classNames(
                  'p-3 rounded-3 border-neutral-200 d-flex align-items-center cursor-pointer',
                  isLearnerAnswerOpen ? 'border-bottom rounded-bottom-0' : 'border',
                )}
              >
                {isLearnerAnswerOpen ? (
                  <ChevronUp
                    strokeWidth={2}
                    size={20}
                    className="gray-900"
                  />
                ) : (
                  <ChevronDown
                    strokeWidth={2}
                    size={20}
                    className="gray-900"
                  />
                )}

                <div className="d-flex align-items-center ms-3 gray-900 font-size-16 fw-semibold">
                  <div className="border-2 border-end border-neutral-200 pe-2">
                    {isLibrariesLoading ? <Skeleton /> : mappingTitle(attempt.content.library)}
                  </div>

                  <div className="ps-2">Learner&apos;s Answers</div>
                </div>
              </div>

              <Collapse isOpen={isLearnerAnswerOpen}>
                <div className="rounded-3">
                  {
                    <ContentResultContainer
                      attemptId={attempt.id}
                      library={attempt.content.library as MachineName}
                    />
                  }
                </div>
              </Collapse>
            </div>
          </div>
        </div>
      )}
    </div>
  );
});
