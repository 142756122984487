import React from 'react';
import './DialogContent.scss';
import { DialogQuestionProps } from './DialogQuestion';
import DialogQuestion from './DialogQuestion';

type DialogProps = {
  title: string;
  questions: Array<DialogQuestionProps>;
};

const DialogContent: React.FC<DialogProps> = ({ title, questions }) => {
  return (
    <div className="dialog-content">
      <div className="question-type-label">Dialog Question</div>
      <div>
        <span className="title-label">Title:</span> {title}
      </div>
      <div className="dialog-questions">
        {questions.map((question, index) => (
          <DialogQuestion
            key={`dialog-question-${index}`}
            {...question}
          />
        ))}
      </div>
    </div>
  );
};

export default DialogContent;
