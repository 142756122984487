import React, { useMemo } from 'react';
import { CrumbItem } from 'components/AppBreadCrumb';
import { PageHeader } from 'components/PageHeader';
import { ContentAttemptLayout } from 'pages/Content/components/ContentAttemptLayout';
import { useQuery } from 'react-query';
import { ErrorResponse } from 'types';
import { fetchLearnerContentAttempt } from 'apis/LearnerReport/LearnerReport';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ContentUserDataAttempt } from 'types/student-report';
import { fetchServerError } from 'utils';

export const LearnerAttemptPage = () => {
  const { attemptId, userId, contentId } = useParams();
  const [searchParam] = useSearchParams();
  const platformId = searchParam.get('platform');
  const navigate = useNavigate();
  const lastAttemptFrom = searchParam.get('lastAttemptFrom') || '';
  const lastAttemptTo = searchParam.get('lastAttemptTo') || '';

  const { data, isLoading } = useQuery<ContentUserDataAttempt, ErrorResponse>(
    ['learner-reports', 'attempts', attemptId],
    () => fetchLearnerContentAttempt(userId, contentId, attemptId, { platformId }),
    {
      retry: false,
      onError: (error: ErrorResponse) => {
        toast(fetchServerError(error), { type: 'error' });
        if (error.statusCode === 404) {
          navigate('/not-found');
        }
      },
    },
  );

  const crumbs: CrumbItem[] = useMemo(() => {
    if (!data) return [];

    return [
      {
        path: `/learner-reports?lastAttemptFrom=${lastAttemptFrom}&lastAttemptTo=${lastAttemptTo}`,
        title: 'All Learners',
      },
      { path: `/learner-reports/${data.user.id}?${searchParam}`, title: data.user.fullName || data.user.email },
      { path: '', title: `Attempt ${data.order + 1}`, unclickable: true },
    ];
  }, [data, searchParam]);

  return (
    <>
      <PageHeader title="Learner Reports" />

      <div className="p-3 p-md-4">
        <ContentAttemptLayout
          isLoading={isLoading}
          attempt={data}
          crumbs={crumbs}
        />
      </div>
    </>
  );
};
