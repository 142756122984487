import React from 'react';
import './JsonContentPreview.scss';
import { ContentLibrary } from '@spiderbox/common';
import CrosswordContent from './Crossword/CrosswordContent';
import DialogContent from './Dialog/DialogContent';
import DragTheWordContent from './DragTheWord/DragTheWordContent';
import BlankContent from './Blank/BlankContent';
import MarkTheWordContent from './MarkTheWord/MarkTheWordContent';
import MultipleChoiceContent from './MultipleChoice/MultipleChoiceContent';
import TrueFalseContent from './TrueFalse/TrueFalseContent';

type JsonContentPreviewProps = {
  jsonData: object;
  index?: number;
};

const JsonContentPreview: React.FC<JsonContentPreviewProps> = ({ jsonData, index }) => {
  return (
    <div className={`json-content-preview pt-3 ${index ? 'border-bottom border-neutral-500' : ''}`}>
      {jsonData['library'] === ContentLibrary.H5P_CROSSWORD && (
        <CrosswordContent
          title={jsonData['metadata']['title']}
          questions={jsonData['params']['words']}
        />
      )}

      {jsonData['library'] === ContentLibrary.H5P_DIALOG_CARDS && (
        <DialogContent
          title={jsonData['metadata']['title']}
          questions={jsonData['params']['dialogs']}
        />
      )}

      {jsonData['library'] === ContentLibrary.H5P_DRAG_TEXT && (
        <DragTheWordContent
          title={jsonData['metadata']['title']}
          questions={[{ textField: jsonData['params']['textField'] }]}
          index={index}
        />
      )}

      {jsonData['library'] === ContentLibrary.H5P_BLANKS && (
        <BlankContent
          title={jsonData['metadata']['title']}
          questions={jsonData['params']['questions']}
          index={index}
        />
      )}

      {jsonData['library'] === ContentLibrary.H5P_MARK_THE_WORDS && (
        <MarkTheWordContent
          title={jsonData['metadata']['title']}
          questions={[{ textField: jsonData['params']['textField'] }]}
          index={index}
        />
      )}

      {jsonData['library'] === ContentLibrary.H5P_MULTIPLE_CHOICE && (
        <MultipleChoiceContent
          index={index}
          title={jsonData['metadata']['title']}
          questions={[
            {
              question: jsonData['params']['question'],
              answers: jsonData['params']['answers'],
            },
          ]}
        />
      )}

      {jsonData['library'] === ContentLibrary.H5P_TRUE_FALSE && (
        <TrueFalseContent
          title={jsonData['metadata']['title']}
          questions={[
            {
              question: jsonData['params']['question'],
              correct: jsonData['params']['correct'],
            },
          ]}
          index={index}
        />
      )}

      {jsonData['library'] === ContentLibrary.H5P_QUESTION_SET &&
        jsonData['params']['questions'].map((question: any, index: number) => (
          <JsonContentPreview
            key={`question-set-${index}`}
            jsonData={question}
            index={index + 1}
          />
        ))}
    </div>
  );
};

export default JsonContentPreview;
