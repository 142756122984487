import React, { useState } from 'react';
import classNames from 'classnames';
import { useAppStore } from 'hooks/useAppStore';
import { observer } from 'mobx-react-lite';
import './PlanOptions.scss';
import { CheckCircleIcon } from 'assets/icons/CheckCircleIcon';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { XCircleIcon } from 'assets/icons/XCircleIcon';
import { Check } from 'react-feather';
import { useGetPlans, useLogout, useSubscriptionCheckout } from 'hooks';
import { Loading, useSubscriptionModal } from 'components';
import { aiAssistantServiceLines, studentReportServiceLines } from 'utils';
import { SubscriptionPlan, SubscriptionStatus } from '@spiderbox/common';
import creditCard from 'assets/images/credit-card.svg';
import creditCardMobile from 'assets/images/credit-card-mobile.svg';
import { ReactSVG } from 'react-svg';
import moment from 'moment';
import { useMeasure } from 'react-use';

export const PlanOptions = observer(({ onboarding = false }: { onboarding?: boolean }) => {
  const {
    subscriptionStore: { plans, currentSubscription },
    workspaceStore: { isWorkspaceOwner },
  } = useAppStore();

  const { isLoading: isPlansLoading } = useGetPlans();

  const [enableStudentReport, setEnableStudentReport] = useState<boolean>(!!currentSubscription?.studentReportItem);

  const [enableAIAssistant] = useState<boolean>(true);

  const subscriptionEndsAt = moment(currentSubscription?.currentPeriodEnd).format('LL');

  const { mutate: logout } = useLogout();

  const { mutate: checkout, isLoading: isCheckoutLoading } = useSubscriptionCheckout();

  const { openCancelPlanModal, openDeactivateStudentReportModal, openActivateStudentReportModal } =
    useSubscriptionModal();

  const [learnerReportPriceRef, { height }] = useMeasure();

  return isPlansLoading ? (
    <Loading />
  ) : (
    <div className={classNames('plan-options-layout d-flex flex-column', { onboarding })}>
      <div className="plan-options-card standard-plan">
        <div className="plan-options-card--icon">
          <CheckCircleIcon />
        </div>
        <div className="plan-options-card--info standard-plan">
          <div className="d-flex flex-column-reverse flex-lg-row justify-content-between">
            <div className="d-flex flex-column align-self-start align-self-lg-center">
              <div className="text-white fw-bolder fs-4">Standard Plan</div>
              <div className="text-neutral-50 font-size-16">Price based on monthly users (minimum 3 users)</div>
            </div>

            <div className="align-self-end d-block d-lg-none">
              <ReactSVG src={creditCardMobile} />
            </div>

            <div className="align-self-center d-none d-lg-block">
              <ReactSVG src={creditCard} />
            </div>
          </div>
        </div>
        <div className="plan-options-card--price standard-plan">
          <div className="gray-900 fs-4xl fw-semibold">${plans[SubscriptionPlan.STANDARD_USER]?.price * 3}</div>
          <div className="text-neutral-600 font-size-16">${plans[SubscriptionPlan.STANDARD_USER]?.price} per user</div>
        </div>
      </div>

      <div className="d-flex flex-column flex-lg-row gap-3 mt-3">
        <div className="plan-options-card w-100 w-lg-50">
          <div className="plan-options-card--icon">{enableStudentReport ? <CheckCircleIcon /> : <XCircleIcon />}</div>
          <div className="plan-options-card--info">
            {onboarding ? (
              <>
                <div className="text-neutral-900 font-size-16 fw-semibold mb-3">Optional features</div>
                <div className="d-flex">
                  <label className="d-flex cursor-pointer form-check checkbox-lg gray-500 mb-0">
                    <input
                      type="checkbox"
                      checked={enableStudentReport}
                      onChange={event => setEnableStudentReport(event.target.checked)}
                      className="form-check-input cursor-pointer primary-500 me-3 mt-2"
                      data-cy="student-report-checkbox"
                    />
                    <div>
                      <div className="text-neutral-900 fw-bolder fs-4">Learner Report</div>
                      <div className="text-neutral-600 font-size-16">Price based on monthly active learners</div>
                    </div>
                  </label>
                </div>
              </>
            ) : (
              <>
                <div className="text-neutral-900 fw-bolder fs-4">Learner Report</div>
                <div className="text-neutral-600 font-size-16">Price based on monthly active learners</div>
              </>
            )}

            <div className="mt-3">
              <div className="gray-900 font-size-16">
                Learner answers are stored and presented in reports when they submit Evokio questions through LTI. This
                feature enables you to gain valuable learner performance information:
              </div>

              <div className="d-flex flex-column">
                {studentReportServiceLines.map((item, index) => {
                  return (
                    <div
                      className="plan-options-card--info__report-line"
                      key={index}
                    >
                      <div className="icon">
                        <Check
                          className={enableStudentReport ? 'text-primary' : 'text-neutral-300'}
                          size={20}
                        />
                      </div>

                      <div className="text-neutral-900 font-size-16">{item}</div>
                    </div>
                  );
                })}
              </div>

              <div className="text-neutral-900 font-size-16 mt-3">
                *Reports are available for content items that offer scoring capabilities.
              </div>
            </div>
          </div>
          <div
            className="w-100 h-100"
            ref={learnerReportPriceRef}
          >
            <div className="plan-options-card--price">
              <div className="gray-900 fs-4xl fw-semibold">${plans[SubscriptionPlan.STUDENT_REPORT]?.price}</div>
              <div className="text-neutral-600 font-size-16">per active learner</div>
              {!onboarding && (
                <div
                  className="mt-3 plan-options-card__report-btn"
                  data-cy="activate-report-btn"
                >
                  {!currentSubscription.cancelStudentReportAtPeriodEnd && currentSubscription.studentReportItem && (
                    <button
                      id="deactivate-student-report"
                      className="btn btn-outline-primary"
                      onClick={openDeactivateStudentReportModal}
                      disabled={
                        currentSubscription?.status === SubscriptionStatus.PAST_DUE ||
                        currentSubscription?.cancelAtPeriodEnd
                      }
                      data-cy="deactivate-student-report"
                    >
                      Deactivate Report
                    </button>
                  )}

                  {(currentSubscription.cancelStudentReportAtPeriodEnd || !currentSubscription.studentReportItem) && (
                    <button
                      id="activate-student-report"
                      className="btn btn-primary"
                      onClick={() => {
                        openActivateStudentReportModal(() => setEnableStudentReport(true));
                      }}
                      disabled={
                        currentSubscription?.status === SubscriptionStatus.PAST_DUE ||
                        currentSubscription?.cancelAtPeriodEnd
                      }
                      data-cy="activate-student-report"
                    >
                      Add Now
                    </button>
                  )}

                  {!currentSubscription.cancelAtPeriodEnd && (
                    <div
                      className={classNames(
                        'text-success-600 font-size-16 mt-3',
                        currentSubscription.cancelStudentReportAtPeriodEnd
                          ? 'opacity-100'
                          : 'opacity-0 cursor-default d-none d-lg-block',
                        {
                          'd-lg-none':
                            !currentSubscription.cancelStudentReportAtPeriodEnd &&
                            !currentSubscription.cancelAIAssistantAtPeriodEnd,
                        },
                      )}
                    >
                      Learner Report cancellation request is received. It will remain active until {subscriptionEndsAt}.
                      Click &quot;Add Now&quot; to reactivate it.
                    </div>
                  )}

                  {currentSubscription?.status === SubscriptionStatus.PAST_DUE && (
                    <UncontrolledTooltip
                      placement="bottom"
                      container="body"
                      target={enableStudentReport ? 'deactivate-student-report' : 'activate-student-report'}
                    >
                      Due to an unpaid invoice, this action is temporarily disabled. Please settle the outstanding
                      payment to regain access.
                    </UncontrolledTooltip>
                  )}

                  {currentSubscription?.cancelAtPeriodEnd && (
                    <UncontrolledTooltip
                      placement="bottom"
                      container="body"
                      target={
                        !enableStudentReport || currentSubscription?.cancelStudentReportAtPeriodEnd
                          ? 'activate-student-report'
                          : 'deactivate-student-report'
                      }
                    >
                      This action is temporarily disabled due to your subscription cancellation request. Please resume
                      your plan to regain access.
                    </UncontrolledTooltip>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="plan-options-card w-100 w-lg-50">
          <div className="plan-options-card--icon">{!onboarding ? <CheckCircleIcon /> : <></>}</div>
          <div className="plan-options-card--info">
            {onboarding ? (
              <>
                <div className="text-neutral-900 font-size-16 fw-semibold mb-3">AI Beta version</div>
                <div className="d-flex">
                  <div className="d-flex align-items-center gap-3">
                    <div className="text-neutral-900 fw-bolder fs-4">Michi Assistant</div>
                    <div className="beta-badge fw-medium">Beta</div>
                  </div>
                </div>
              </>
            ) : (
              <div className="d-flex align-items-center gap-3">
                <div className="text-neutral-900 fw-bolder fs-4">Michi Assistant</div>
                <div className="beta-badge fw-medium">Beta</div>
              </div>
            )}

            <div className="mt-3">
              <div className="gray-900 font-size-16">Unlock AI-Powered Creativity with our Michi AI Assistant:</div>

              <div className="d-flex flex-column">
                {aiAssistantServiceLines.map((item, index) => {
                  return (
                    <div
                      className="plan-options-card--info__report-line"
                      key={index}
                    >
                      <div className="icon">
                        <Check
                          className={enableAIAssistant ? 'text-primary' : 'text-neutral-300'}
                          size={20}
                        />
                      </div>

                      <div className="text-neutral-900 font-size-16">{item}</div>
                    </div>
                  );
                })}
              </div>

              <div className="text-neutral-900 font-size-16 mt-3">
                Let AI revolutionize the way you design quizzes, assessments, and games for your learners!
              </div>
            </div>
          </div>
          <div
            className="plan-options-card--price ai-assistant-price d-flex flex-column justify-content-center align-items-center"
            style={{ height }}
          >
            <div className="free-badge text-primary font-size-18 fw-semibold">Free During BETA</div>
            <div className="text-neutral-600 font-size-16 text-center">
              We&apos;re constantly working to enhance Michi.
              <br />
              Stay tuned for updates!
            </div>
          </div>
        </div>
      </div>

      {onboarding ? (
        <div className="plan-options-footer d-flex justify-content-between flex-column flex-lg-row">
          <Button
            outline
            color="primary"
            onClick={() => logout(false)}
          >
            Log Out
          </Button>

          <Button
            color="primary"
            onClick={() => checkout({ withStudentReport: enableStudentReport })}
            disabled={isCheckoutLoading}
          >
            {isCheckoutLoading && (
              <span
                className="spinner-border spinner-border-sm me-2"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            Continue
          </Button>
        </div>
      ) : (
        isWorkspaceOwner &&
        [SubscriptionStatus.ACTIVE, SubscriptionStatus.TRIALING].includes(currentSubscription?.status) && (
          <div className="mt-4">
            <button
              className={classNames(
                'btn',
                currentSubscription.cancelAtPeriodEnd ? 'btn-primary' : 'btn-outline-primary',
              )}
              onClick={() => openCancelPlanModal(currentSubscription.cancelAtPeriodEnd)}
              data-cy="cancel-plan-btn"
            >
              {currentSubscription.cancelAtPeriodEnd ? 'Resume' : 'Cancel'} Plan
            </button>
          </div>
        )
      )}
    </div>
  );
});
