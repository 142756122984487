import React from 'react';
import './MarkTheWordQuestion.scss';
import Markdown from 'react-markdown';

export type MarkTheWordQuestionProps = {
  textField: string;
};

const MarkTheWordQuestion: React.FC<MarkTheWordQuestionProps> = ({ textField }) => {
  return (
    <div className="mark-the-word-question">
      <Markdown className="custom-markdown">{textField}</Markdown>
    </div>
  );
};

export default MarkTheWordQuestion;
