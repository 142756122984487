import { ContentLibrary } from '@spiderbox/common';

export type QuestionType = {
  params: {
    questions?: QuestionType[] | string[];
    correct?: boolean;
    question?: string;
    answers?: { text: string; correct: boolean }[];
    textField?: string;
    taskDescription?: string;
    words: { answer: string; clue: string }[];
    dialogs: { answer: string; text: string }[];
  };
  library: string;
  metadata: {
    title: string;
  };
};

const trueFalseContentToMarkdownString = (contentJson: QuestionType, index?: number): string => {
  const { params, metadata } = contentJson;
  const { question, correct } = params;
  const title = metadata?.title;
  const contentType = 'True or False Question';

  return `**${index ? `${index}.` : ''} ${contentType}**\n\n**Title:** ${title}\n\n${question}\n\n**Answer:** ${
    correct ? 'True' : 'False'
  }\n\n`;
};

const multipleChoiceContentToMarkdownString = (contentJson: QuestionType, index?: number): string => {
  const { params, metadata } = contentJson;
  const { question, answers } = params;
  const title = metadata?.title;
  const contentType = 'Multiple Choice Question';
  const allAlphabets = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  const answersString = answers
    .map((answer, index) => {
      return `${answer.correct ? '**' : ''}${allAlphabets[index]}) ${answer.text}${answer.correct ? '**' : ''}\n\n`;
    })
    .join('\n');

  return `**${
    index ? `${index}.` : ''
  } ${contentType}**\n\n**Title:** ${title}\n\n**Question:** ${question}\n\n**Answers:**\n\n${answersString}`;
};

const blanksContentToMarkdownString = (contentJson: QuestionType, index?: number): string => {
  const { params, metadata } = contentJson;
  const { questions } = params;
  const title = metadata?.title;
  const contentType = 'Fill in the Blank Question';

  const questionsString = questions
    .map(question => {
      return `${question}\n\n`;
    })
    .join('\n');

  return `**${index ? `${index}. ` : ''}${contentType}**\n\n**Title:** ${title}\n\n${questionsString}`;
};

const markTheWordsContentToMarkdownString = (contentJson: QuestionType, index?: number): string => {
  const { params, metadata } = contentJson;
  const { textField } = params;
  const title = metadata?.title;
  const contentType = 'Mark The Word Question';

  return `**${index ? `${index}. ` : ''}${contentType}**\n\n**Title:** ${title}\n\n${textField}\n\n`;
};

const dragTextContentToMarkdownString = (contentJson: QuestionType, index?: number): string => {
  const { params, metadata } = contentJson;
  const { textField, taskDescription } = params;
  const title = metadata?.title;
  const contentType = 'Drag The Word Question';

  return `**${
    index ? `${index}. ` : ''
  } ${contentType}**\n\n**Title:** ${title}\n\n${taskDescription}\n\n${textField}\n\n`;
};

const crosswordContentToMarkdownString = (contentJson: QuestionType): string => {
  const { params, metadata } = contentJson;
  const { words } = params;
  const title = metadata?.title;

  const wordsString = words
    .map(question => {
      return `**Clue**: ${question.clue}\n**Answer**: ${question.answer}`;
    })
    .join('\n\n');

  return `**${title}**\n\n${wordsString}`;
};

const dialogsContentToMarkdownString = (contentJson: QuestionType): string => {
  const { params, metadata } = contentJson;
  const { dialogs } = params;
  const title = metadata?.title;

  const dialogsString = dialogs
    .map(dialog => {
      return `**Text**: ${dialog.text}\n**Answer**: ${dialog.answer}\n\n`;
    })
    .join('\n');

  return `**${title}**\n\n${dialogsString}`;
};

const questionSetContentToMarkdownString = (contentJson: QuestionType): string => {
  const { params, metadata } = contentJson;
  const { questions } = params;
  const title = metadata?.title;

  const questionsString = questions
    .map((question, index) => {
      return extractContentByLibrary(question, index + 1);
    })
    .join('\n-----------------------------------------------------------------------------------\n');

  return `**${title}**\n\n${questionsString}`;
};

export const extractContentByLibrary = (contentJson: QuestionType, index?: number) => {
  const { library } = contentJson;

  switch (library) {
    case ContentLibrary.H5P_CROSSWORD:
      return crosswordContentToMarkdownString(contentJson);
    case ContentLibrary.H5P_DIALOG_CARDS:
      return dialogsContentToMarkdownString(contentJson);
    case ContentLibrary.H5P_MULTIPLE_CHOICE:
      return multipleChoiceContentToMarkdownString(contentJson, index);
    case ContentLibrary.H5P_TRUE_FALSE:
      return trueFalseContentToMarkdownString(contentJson, index);
    case ContentLibrary.H5P_BLANKS:
      return blanksContentToMarkdownString(contentJson, index);
    case ContentLibrary.H5P_MARK_THE_WORDS:
      return markTheWordsContentToMarkdownString(contentJson, index);
    case ContentLibrary.H5P_DRAG_TEXT:
      return dragTextContentToMarkdownString(contentJson, index);
    case ContentLibrary.H5P_QUESTION_SET:
      return questionSetContentToMarkdownString(contentJson);
    default:
      throw new Error(`Library type ${library} not supported.`);
  }
};
