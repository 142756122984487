import React from 'react';
import './BlankQuestion.scss';
import Markdown from 'react-markdown';

export type BlankQuestionProps = {
  textField: string;
};

const BlankQuestion: React.FC<BlankQuestionProps> = ({ textField }) => {
  return (
    <div className="blank-question">
      <Markdown className="custom-markdown">{textField}</Markdown>
    </div>
  );
};

export default BlankQuestion;
