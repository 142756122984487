import { IContentMetadata, IEditorModel, IPlayerModel } from '@lumieducation/h5p-server';
import { ContentSetting } from '@spiderbox/common';
import { getContent, getEdit, getPlay, getPreview, save } from 'apis/Content/Content';
import { moveFolder } from 'apis/Folder/Folder';
import { mapToContentPermissions } from 'hooks';
import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { ContentStatus, UserContentDto } from 'models/Content';
import { ContentActionStatus, ContentPermissions, ContentPermissionsInput } from 'types';
import { isEqual } from 'lodash';

type CreateUpdateContentPayload = {
  contentId: string;
  folderId?: string;
  requestBody: {
    library: string;
    params: {
      metadata?: IContentMetadata;
      params: any;
    };
  };
};

type EditorModel = Partial<IEditorModel> & {
  library?: string;
  metadata?: IContentMetadata;
  params?: any;
  contentId?: string;
};

export class ContentStore {
  contentId: string = null;
  folderId: string = null;
  pageTitle: string = '';
  library: string = '';
  contentStatus = ContentStatus.Draft;
  actionStatus: ContentActionStatus = ContentActionStatus.DRAFT;
  owner: UserContentDto;
  settings: ContentSetting = null;
  h5pEditorRef: React.MutableRefObject<any> = null;
  isDirty = false;
  isSaving = false;
  isContentLoaded = false;
  editor: EditorModel = null;
  permissionsInput: ContentPermissionsInput = null;
  totalUserAttempts: number = null;

  constructor() {
    makeAutoObservable(this);
  }

  get permissions(): ContentPermissions {
    return this.permissionsInput ? mapToContentPermissions(this.permissionsInput) : null;
  }

  setDirty(isDirty: boolean) {
    this.isDirty = isDirty;
  }

  setIsSaving(isSaving: boolean) {
    this.isSaving = isSaving;
  }

  setH5PEditorRef(ref: React.MutableRefObject<any>) {
    this.h5pEditorRef = ref;
  }

  clearH5PEditorRef() {
    this.h5pEditorRef = null;
  }

  async loadEditorAsync(contentId: string, library?: string, aiMessageId?: string) {
    if (this.editor && this.editor.contentId === contentId && this.editor.library === library) {
      return Promise.resolve(toJS(this.editor) as IEditorModel);
    }

    this.isContentLoaded = false;
    const editor = await getEdit(contentId, aiMessageId);
    this.editor = { ...editor, contentId, library: editor.library || library };
    this.isContentLoaded = true;
    return toJS(this.editor as IEditorModel);
  }

  private async getContentAsync(
    loader: (options: any) => Promise<IPlayerModel>,
    contentId: string,
    contextId?: string,
    asUserId?: string,
    readOnlyState?: boolean,
  ): Promise<IPlayerModel> {
    const content = await loader({ contentId, contextId, asUserId, readOnlyState });

    return content;
  }

  loadContentPlayAsync = async (
    contentId: string,
    contextId?: string,
    asUserId?: string,
    readOnlyState?: boolean,
  ): Promise<IPlayerModel> => {
    return this.getContentAsync(getPlay, contentId, contextId, asUserId, readOnlyState);
  };

  loadContentPreviewAsync = async (
    contentId: string,
    contextId?: string,
    asUserId?: string,
    readOnlyState?: boolean,
  ): Promise<IPlayerModel> => {
    return this.getContentAsync(
      options =>
        getPreview({
          ...options,
          library: this.editor?.library,
          parameters: this.editor?.params,
          metadata: this.editor?.metadata,
          settings: this.settings,
        }),
      contentId,
      contextId,
      asUserId,
      readOnlyState,
    );
  };

  storeEditor(editor: EditorModel) {
    // Check if editor params or metadata is changed (first assignment, from undefined to object is considered as changed)
    if (
      (!!this.editor?.params && !isEqual(editor.params, this.editor?.params)) ||
      (!!this.editor?.metadata && !isEqual(editor.metadata, this.editor?.metadata))
    ) {
      this.isDirty = true;
    }

    this.editor = {
      ...this.editor,
      ...editor,
      integration: {
        ...this.editor.integration,
        editor: {
          ...this.editor.integration?.editor,
          nodeVersionId: this.editor.integration?.editor?.nodeVersionId || 'new-content',
        },
      },
    };
  }

  async loadContentAsync(contentId: string) {
    if (!contentId || contentId === 'new-content') {
      this.clear();
      this.pageTitle = 'Create New Content';
      this.isContentLoaded = true;
      return;
    }

    this.isContentLoaded = false;

    try {
      // If contentId is not changed, we don't need to load content again
      if (this.contentId) {
        runInAction(() => {
          this.isContentLoaded = true;
        });
        return;
      }

      const content = await getContent(contentId);
      const { id, title, status, setting, library, user, totalUserAttempts } = content;
      runInAction(() => {
        this.contentId = id;
        this.library = library;
        this.pageTitle = title;
        this.contentStatus = status;
        this.settings = setting;
        this.owner = user;
        this.totalUserAttempts = totalUserAttempts;
      });
    } catch (error) {
    } finally {
      runInAction(() => {
        this.isContentLoaded = true;
      });
    }
  }

  setFolderId(folderId: string) {
    if (folderId && this.folderId !== folderId) {
      this.isDirty = true;
    }
    this.folderId = folderId;
  }

  setSettings(settings: ContentSetting) {
    this.settings = { ...settings };
  }

  setPermissionsInput(permissionsInput: ContentPermissionsInput) {
    this.permissionsInput = { ...permissionsInput };
  }

  setActionStatus(status: ContentActionStatus) {
    this.actionStatus = status;
  }

  async saveContentAsync({ contentId, requestBody }: CreateUpdateContentPayload) {
    contentId = contentId === 'new-content' ? undefined : contentId;
    this.isSaving = true;
    if (
      this.actionStatus === ContentActionStatus.PUBLISH ||
      this.actionStatus === ContentActionStatus.SAVE_AND_INSERT
    ) {
      this.contentStatus = ContentStatus.Published;
    }

    try {
      const content = await save({
        contentId,
        requestBody,
        parentId: this.folderId,
        setting: this.settings,
        status: this.contentStatus || ContentStatus.Draft,
        permissions: this.permissions && { ...this.permissions, contentId },
      });

      const { id, metadata } = content;

      if (contentId && this.folderId) {
        await moveFolder({
          id,
          targetId: this.folderId,
        });
      }

      await this.loadContentAsync(id);

      runInAction(() => {
        this.contentId = id;
        this.pageTitle = metadata['title'] as string;
        this.isSaving = false;
        this.permissionsInput = null;
        this.isDirty = false;
      });

      return content;
    } catch (error) {
      this.setIsSaving(false);
    }
  }

  clear() {
    this.contentId = null;
    this.pageTitle = '';
    this.library = '';
    this.contentStatus = ContentStatus.Draft;
    this.actionStatus = ContentActionStatus.DRAFT;
    this.owner = null;
    this.permissionsInput = null;
    this.isDirty = false;
    this.isSaving = false;
    this.isContentLoaded = false;
    this.editor = null;
    this.totalUserAttempts = null;
  }
}
