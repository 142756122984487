import React from 'react';
import './MultipleChoiceQuestion.scss';

export type MultipleChoiceQuestionProps = {
  answers: { text: string; correct: boolean }[];
  question: string;
};

const MultipleChoiceQuestion: React.FC<MultipleChoiceQuestionProps> = ({ question, answers }) => {
  return (
    <div className="multiple-choice-question">
      <div className="text">
        <span className="question-label">Question:</span> {question}
      </div>

      <div className="mt-2 answer-label">Answers:</div>
      <ol className="answers">
        {answers.map((answer, index) => (
          <li
            key={`multiple-choice-answer-${index}`}
            className={`answer answer-${answer.correct}`}
          >
            {answer.text}
          </li>
        ))}
      </ol>
    </div>
  );
};

export default MultipleChoiceQuestion;
