import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { addMessageToChatHistory } from 'hooks/ai-chat';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { AIMessageActionType, AIMessageContentType, AIMessageType, ChatDetailResponse } from 'types';
import './ChatInput.scss';
import TextareaAutosize from 'react-textarea-autosize';

type Props = {
  chat: ChatDetailResponse;
  isLoading: boolean;
  submitInput: ({
    chatId,
    content,
    actionType,
  }: {
    chatId: string;
    content: string;
    actionType: AIMessageActionType;
  }) => void;
};

export const ChatInput = ({ chat, isLoading, submitInput }: Props) => {
  const { control, handleSubmit, reset } = useForm<{ content: string }>();
  const queryClient = useQueryClient();

  const onSubmit = async (value: { content: string }) => {
    const content = value.content;
    const actionType = AIMessageActionType.Chat;

    addMessageToChatHistory({
      queryClient,
      chatId: chat.aiChat.id,
      message: {
        content,
        messageType: AIMessageType.Human,
        actionType,
        messageContentType: AIMessageContentType.Text,
      },
    });
    reset({});
    submitInput({ chatId: chat.aiChat.id, content, actionType });
  };

  const handleKeydown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const content = (event.target as HTMLTextAreaElement).value?.trim() || '';

    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      if (content !== '' && !isLoading) {
        onSubmit({ content });
      }
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="form-group d-flex align-items-center gap-3 sticky-bottom bg-white pb-5"
    >
      <Controller
        control={control}
        name="content"
        defaultValue=""
        render={({ field }) => (
          <div className="gap-3 w-100 d-flex form-container">
            <TextareaAutosize
              {...field}
              minRows={1}
              maxRows={3}
              placeholder="Describe the topic of your content..."
              onKeyDown={(e: React.KeyboardEvent<HTMLTextAreaElement>) => handleKeydown(e)}
            />
            <div className="d-flex align-items-center">
              <button
                disabled={!field.value || isLoading}
                className="btn btn-primary text-white rounded-circle d-flex justify-content-center align-items-center p-2"
              >
                <ArrowRightIcon
                  className="text-white"
                  width={16}
                  height={16}
                  strokeWidth={2}
                />
              </button>
            </div>
          </div>
        )}
      />
    </form>
  );
};
