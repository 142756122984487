import React from 'react';
import './DialogQuestion.scss';

export type DialogQuestionProps = {
  answer: string;
  text: string;
};

const DiaLogQuestion: React.FC<DialogQuestionProps> = ({ text, answer }) => {
  return (
    <div className="dialog-question">
      <div className="text">
        <span className="question-label">Text:</span> {text}
      </div>
      <div className="answer">
        <span className="answer-label">Answer:</span> {answer}
      </div>
    </div>
  );
};

export default DiaLogQuestion;
