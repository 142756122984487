import React from 'react';
import './MarkTheWordContent.scss';
import { MarkTheWordQuestionProps } from './MarkTheWordQuestion';
import MarkTheWordQuestion from './MarkTheWordQuestion';

type MarkTheWordProps = {
  title: string;
  questions: Array<MarkTheWordQuestionProps>;
  index?: number;
};

const MarkTheWordContent: React.FC<MarkTheWordProps> = ({ title, questions, index }) => {
  return (
    <div className="mark-the-word-content">
      <div className="question-type-label">{`${index ? index + '.' : ''} Mark The Word Question`}</div>
      <div>
        <span className="title-label">Title: </span> {title}
      </div>
      <div className="mark-the-word-questions mt-2">
        {questions.map((question, index) => (
          <MarkTheWordQuestion
            key={`mark-the-word-question-${index}`}
            {...question}
          />
        ))}
      </div>
    </div>
  );
};

export default MarkTheWordContent;
