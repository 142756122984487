import React from 'react';
import './MultipleChoiceContent.scss';
import { MultipleChoiceQuestionProps } from './MultipleChoiceQuestion';
import MultipleChoiceQuestion from './MultipleChoiceQuestion';

type MultipleChoiceProps = {
  title: string;
  questions: Array<MultipleChoiceQuestionProps>;
  index?: number;
};

const MultipleChoiceContent: React.FC<MultipleChoiceProps> = ({ title, questions, index }) => {
  return (
    <div className="multiple-choice-content">
      <div className="question-type-label">{`${index ? index + '.' : ''} MultipleChoice Question`}</div>
      <div>
        <span className="title-label">Title:</span> {title}
      </div>
      <div className="multiple-choice-questions">
        {questions.map((question, index) => (
          <MultipleChoiceQuestion
            key={index}
            {...question}
          />
        ))}
      </div>
    </div>
  );
};

export default MultipleChoiceContent;
