import React from 'react';
import './DragTheWordQuestion.scss';
import Markdown from 'react-markdown';

export type DragTheWordQuestionProps = {
  textField: string;
};

const DragTheWordQuestion: React.FC<DragTheWordQuestionProps> = ({ textField }) => {
  return (
    <div className="drag-the-word-question">
      <Markdown className="custom-markdown">{textField}</Markdown>
    </div>
  );
};

export default DragTheWordQuestion;
