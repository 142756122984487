import React from 'react';
import './TrueFalseContent.scss';
import { TrueFalseQuestionProps } from './TrueFalseQuestion';
import TrueFalseQuestion from './TrueFalseQuestion';

type TrueFalseProps = {
  title: string;
  questions: Array<TrueFalseQuestionProps>;
  index?: number;
};

const TrueFalseContent: React.FC<TrueFalseProps> = ({ title, questions, index }) => {
  return (
    <div className="true-false-content">
      <div className="question-type-label">{`${index ? index + '.' : ''} True or False Question`}</div>
      <div>
        <span className="title-label">Title:</span>
        {title}
      </div>
      <div className="true-false-questions">
        {questions.map((question, index) => (
          <TrueFalseQuestion
            key={index}
            {...question}
          />
        ))}
      </div>
    </div>
  );
};

export default TrueFalseContent;
