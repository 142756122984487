import React from 'react';
import './CrosswordQuestion.scss';

export type CrosswordQuestionProps = {
  answer: string;
  clue: string;
};

const CrosswordQuestion: React.FC<CrosswordQuestionProps> = ({ clue, answer }) => {
  return (
    <div className="crossword-question">
      <div>
        <span className="answer-label">Clue:</span> {clue}
      </div>
      <div>
        <span className="answer-label">Answer:</span> {answer}
      </div>
    </div>
  );
};

export default CrosswordQuestion;
