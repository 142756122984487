import React from 'react';
import './BlankContent.scss';
import BlankQuestion from './BlankQuestion';

type BlankProps = {
  title: string;
  questions: Array<string>;
  index?: number;
};

const BlankContent: React.FC<BlankProps> = ({ title, questions, index }) => {
  return (
    <div className="blank-content">
      <div className="question-type-label">{`${index ? index + '.' : ''} Fill in the Blank Question`}</div>
      <div>
        <span className="title-label">Title:</span> {title}
      </div>
      <div className="blank-questions">
        {questions.map((question, index) => (
          <BlankQuestion
            key={`Blank-question-${index}`}
            textField={question}
          />
        ))}
      </div>
    </div>
  );
};

export default BlankContent;
