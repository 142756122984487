import React from 'react';
import './CrosswordContent.scss';
import { CrosswordQuestionProps } from './CrosswordQuestion';
import CrosswordQuestion from './CrosswordQuestion';

type CrosswordProps = {
  title: string;
  questions: Array<CrosswordQuestionProps>;
};

const CrosswordContent: React.FC<CrosswordProps> = ({ title, questions }) => {
  return (
    <div className="crossword-content">
      <div className="question-type-label">Crossword Question</div>
      <div>
        <span className="title-label">Title:</span> {title}
      </div>
      <div className="crossword-questions">
        {questions.map((question, index) => (
          <CrosswordQuestion
            key={index}
            {...question}
          />
        ))}
      </div>
    </div>
  );
};

export default CrosswordContent;
