import React from 'react';
import './TrueFalseQuestion.scss';

export type TrueFalseQuestionProps = {
  correct: boolean;
  question: string;
};

const TrueFalseQuestion: React.FC<TrueFalseQuestionProps> = ({ question, correct }) => {
  return (
    <div className="true-false-question">
      <div className="question">
        <span className="question-label">Question: </span>
        {question}
      </div>
      <div>
        <span className="answer-label">Answer: </span>
        <span className="text-capitalize">{correct.toString()}</span>
      </div>
    </div>
  );
};

export default TrueFalseQuestion;
