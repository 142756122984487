import React, { useMemo } from 'react';
import { CellContext, Row, createColumnHelper } from '@tanstack/react-table';
import { fetchLearnerReports } from 'apis/LearnerReport/LearnerReport';
import { AppBadge } from 'components';
import { useDownloadLearnerReports, useGetOptionsConnections } from 'hooks';
import moment from 'moment';
import { LearnerReport } from 'types/student-report';
import { LearnerReportLayout } from '../LearnerReportLayout';
import Skeleton from 'react-loading-skeleton';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const LearnerReportsPage = () => {
  const { isLoading: isDownloadLoading, mutate: downloadLearnerReportsCsv } = useDownloadLearnerReports();
  const navigate = useNavigate();
  const [searchParam] = useSearchParams();
  const { data, isLoading } = useGetOptionsConnections({ cacheTime: 300000 });

  const connectionsData = useMemo(() => {
    if (!data?.length) return {};

    return data.reduce((current, { kid, platformName }) => {
      return { ...current, [kid]: platformName };
    }, {});
  }, [data]);

  const onRowClick = (row: Row<LearnerReport>) => {
    const report = row.original;
    const lastAttemptFrom = searchParam.get('lastAttemptFrom') || '';
    const lastAttemptTo = searchParam.get('lastAttemptTo') || '';
    const platformId = searchParam.get('platform') || report.platformId;
    const userInfo = report.fullName || report.email;

    navigate(
      `${report.id}?lastAttemptFrom=${lastAttemptFrom}&lastAttemptTo=${lastAttemptTo}&platform=${platformId}&user=${userInfo}&platformName=${connectionsData[platformId]}`,
    );
  };

  const columnHelper = createColumnHelper<LearnerReport>();

  const defaultColumns = [
    columnHelper.display({
      id: 'user-info',
      header: 'Learner',
      meta: {
        className: 'w-40 ps-3',
      },
      cell: ({ row }) => {
        const fullName = row.original?.fullName;

        return (
          <div className="px-2 d-flex align-items-center">
            <div className="ms-1 flex-fill w-1px">
              <div className="text-truncate gray-900 fw-semibold text-capitalize">{fullName}</div>
              <div className="text-truncate gray-900">{row.original.email}</div>
            </div>
          </div>
        );
      },
    }),

    columnHelper.display({
      id: 'lms-connection',
      header: 'LMS Connection',
      meta: {
        className: 'w-40',
      },
      cell: ({ row }) => {
        const platformId = row.original.platformId;

        return isLoading ? (
          <Skeleton />
        ) : (
          <div className="d-flex pe-2">
            <div className="gray-900 flex-fill w-1px text-truncate">{connectionsData[platformId]}</div>
          </div>
        );
      },
    }),

    columnHelper.accessor('totalAttempts', {
      id: 'totalAttempts',
      header: () => <div className="d-flex justify-content-center">Attempts</div>,
      size: 175,
      cell: (info: CellContext<LearnerReport, number>) => {
        return (
          <div className="d-flex justify-content-center">
            <AppBadge
              type="primary"
              label={<span className="font-size-14">{info.getValue() ?? 0}</span>}
            />
          </div>
        );
      },
    }),

    columnHelper.accessor('lastAttemptAt', {
      id: 'lastAttemptAt',
      header: 'Last Attempt',
      size: 250,
      cell: (info: CellContext<LearnerReport, Date>) => {
        if (!info.getValue()) return <></>;

        return (
          <div className="d-flex">
            <AppBadge
              type="neutral"
              label={<span className="font-size-14">{moment(info.getValue()).format('DD MMM YYYY, HH:mm:ss')}</span>}
            />
          </div>
        );
      },
    }),
  ];

  return (
    <LearnerReportLayout
      queryKey={['learner-reports']}
      fetchReports={fetchLearnerReports}
      downloadCsv={searchTerm => downloadLearnerReportsCsv(searchTerm)}
      pageTitle="Learner Report"
      isDownloadLoading={isDownloadLoading}
      defaultColumns={defaultColumns}
      searchInputPlaceHolder="Search by name or email..."
      canFilterByPlatform={true}
      onRowClick={row => onRowClick(row)}
      crumbs={[{ path: '', title: 'All Learners', unclickable: true }]}
      isLoading={isLoading}
    />
  );
};
