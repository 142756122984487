import { SubscriptionInfo } from '@spiderbox/common';
import apiClient from 'apis/ApiClient';
import { WorkspaceUsageRecord } from 'types';
import { Plan } from 'types/subscriptions';

const endPoint = '/subscription';

export const fetchAllPlans = async (): Promise<Plan[]> => {
  const response = await apiClient.get(`${endPoint}/plans`);

  return response.data;
};

export const getCheckoutSession = async ({ withStudentReport }: { withStudentReport: boolean }) => {
  const response = await apiClient.post(`${endPoint}/checkout`, { withStudentReport });

  return response.data;
};

export const getCurrentSubscription = async (): Promise<SubscriptionInfo> => {
  const response = await apiClient.get(`${endPoint}/current`);

  return response.data;
};

export const resumeSubscription = async () => {
  const response = await apiClient.get(`${endPoint}/resume`);

  return response.data;
};
export const cancelSubscription = async () => {
  const response = await apiClient.get(`${endPoint}/cancel`);

  return response.data;
};

export const syncCurrentSubscription = async (): Promise<SubscriptionInfo> => {
  const response = await apiClient.get(`${endPoint}/sync-current`);

  return response.data;
};

export const activateStudentReport = async (): Promise<SubscriptionInfo> => {
  const response = await apiClient.post(`${endPoint}/student-report/activate`);

  return response.data;
};

export const deactivateStudentReport = async (): Promise<SubscriptionInfo> => {
  const response = await apiClient.post(`${endPoint}/student-report/deactivate`);

  return response.data;
};

export const reactivateStudentReport = async (): Promise<SubscriptionInfo> => {
  const response = await apiClient.post(`${endPoint}/student-report/reactivate`);

  return response.data;
};

export const activateAIAssistant = async (): Promise<SubscriptionInfo> => {
  const response = await apiClient.post(`${endPoint}/ai-assistant/activate`);

  return response.data;
};

export const deactivateAIAssistant = async (): Promise<SubscriptionInfo> => {
  const response = await apiClient.post(`${endPoint}/ai-assistant/deactivate`);

  return response.data;
};

export const reactivateAIAssistant = async (): Promise<SubscriptionInfo> => {
  const response = await apiClient.post(`${endPoint}/ai-assistant/reactivate`);

  return response.data;
};

export const getUsageRecord = async (): Promise<WorkspaceUsageRecord> => {
  return await apiClient.get(`${endPoint}/current/usage-record`).then(response => {
    return response.data;
  });
};

export const getSubscriptionInfo = async (): Promise<SubscriptionInfo> => {
  const response = await apiClient.get(`${endPoint}/info`);

  return response.data;
};
